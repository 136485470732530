import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11')
];

export const server_loads = [2];

export const dictionary = {
		"/": [~3],
		"/auth/login": [~4],
		"/auth/reset-password": [~5],
		"/auth/signup": [~6],
		"/auth/verify-email": [~7],
		"/settings": [8,[2]],
		"/settings/account": [~9,[2]],
		"/settings/privacy": [10,[2]],
		"/settings/security": [11,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';